<template>
  <div>
    <div class="header">
      <div class="logo">
        <img src="../../assets/image/logo.png" alt="" />
        <span class="create">自建文本</span>
        <div>
          <p class="message1">一次录入，多次使用</p>
          <p class="message">APP首页默写记录支持多次复习，反复记忆</p>
        </div>
      </div>
      <div class="user">
        <span class="username">{{ phone }}</span>
        <el-dropdown @command="logout">
          <span class="el-dropdown-link">
            <i class="el-icon-more"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: ""
    };
  },
  created() {
    // this.phone = this.$route.query.name
    this.phone = localStorage.getItem("name");
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      this.$router.push("/");
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 108px;
  background: #fff;
  .logo {
    display: flex;
    align-items: center;
    margin-left: 100px;
    img {
      width: 260px;
    }
    .create {
      margin: 0 48px;
      width: 120px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #fff;
      background: #2158ff;
      border-radius: 12px;
    }
    .message1 {
      font-size: 30px;
      font-weight: 400;
      color: #bababa;
    }
    .message {
      font-size: 20px;
      font-weight: 400;
      color: #bababa;
    }
  }
  .user {
    margin-right: 208px;
    height: 40px;
    line-height: 40px;
    .username {
      margin-right: 30px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #272829;
    }
    .el-icon-more {
      vertical-align: middle;
      color: #2158ff;
      font-size: 30px;
    }
  }
}
</style>
